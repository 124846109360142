import React from 'react';

import icon1 from "/static/icons/how-it-works-icon-1.svg";
import icon2 from "/static/icons/how-it-works-icon-2.svg";
import icon3 from "/static/icons/how-it-works-icon-3.svg";
import icon4 from "/static/icons/how-it-works-icon-4.svg";
import icon5 from "/static/icons/how-it-works-icon-5.svg";

const HowItWorks = () => {
  return (
    <div className="bg-gray-100">
      <div className="max-w-prose lg:max-w-3xl pb-4 px-4 pt-4 sm:pt-8 mx-auto">
        <div className="py-4 sm:py-8 text-center">
          <p className="mt-2 text-3xl font-extrabold tracking-tight text-primary sm:text-4xl">
            How it Works
          </p>
        </div>
      </div>

      <div className="max-w-7xl pb-4 sm:pb-8 mx-auto">
        <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-5 md:gap-y-10 mb-10">

          <figure className="grid space-y-4 md:border-r md:border-gray-300 sm:space-y-4 content-center">
            <div className="h-32">
              <img src={icon1} alt="How It Works Icon 1" link="icon" className="mx-auto my-auto content-center justify-center h-28 md:p-4"/>
            </div>
              <span className="block md:border-t md:border-b border-gray-300 text-xl tracking-wide py-2">
                <div className="flex justify-center items-center">
                Consumer
                </div>
              </span>
            <figcaption className="px-3 h-14 flex flex-col align-start items-center">
              <span className="block text-center px-10 sm:px-20 md:px-1 text-sm text-gray-500">
                Make purchases, takes photo of tax receipt
              </span>
            </figcaption>
          </figure>

          <figure className="grid md:border-r md:border-l sm:border-gray-300 space-y-4 content-center">
            <div className="h-32">
            <img src={icon2} alt="How It Works Icon 2" link="icon" className="mx-auto my-auto content-center justify-center h-28 md:p-4"/>
            </div>
              <span className="block md:border-t md:border-b border-gray-300 text-xl tracking-wide py-2">
                <div className="flex justify-center items-center">
                Consumer
                </div>
              </span>
            <figcaption className="px-3 h-14 flex flex-col align-start items-center">
              <span className="block text-center px-10 sm:px-20 md:px-1 text-sm text-gray-500">
                Registers claim via App or Microsite
              </span>
            </figcaption>
          </figure>

          <figure className="grid md:border-r md:border-l md:border-gray-300 space-y-4 content-center">
            <div className="h-32">
            <img src={icon3} alt="How It Works Icon 3" link="icon" className="mx-auto content-center justify-center h-28 md:p-4"/>
            </div>
              <span className="block md:border-t md:border-b border-gray-300 text-xl tracking-wide py-2">
                <div className="flex justify-center items-center">
                WAIVPAY
                </div>
              </span>
            <figcaption className="px-3 h-14 flex flex-col align-start items-center">
              <span className="block text-center px-10 sm:px-20 md:px-1 text-sm text-gray-500">
                Reviews and either approves or declines dependant on rules and criteria
              </span>
            </figcaption>
          </figure>

          <figure className="grid space-y-4 md:border-r md:border-l md:border-gray-300 sm:space-y-4 content-center">
            <div className="h-32">
            <img src={icon4} alt="How It Works Icon 4" link="icon" className="mx-auto justify-center h-28 md:p-4"/>
            </div>
              <span className="block md:border-t md:border-b border-gray-300 text-xl tracking-wide py-2">
                <div className="flex justify-center items-center">
                Consumer
                </div>
              </span>
            <figcaption className="px-3 h-14 flex flex-col align-start items-center">
              <span className="block text-center px-10 sm:px-20 md:px-1 text-sm text-gray-500">
                Receives funds into bank account
              </span>
            </figcaption>
          </figure>

          <figure className="grid space-y-4 md:border-l md:border-gray-300 sm:space-y-4 content-center">
            <div className="h-32">
            <img src={icon5} alt="How It Works Icon 5" link="icon" className="mx-auto justify-center h-28 md:p-4"/>
            </div>
              <span className="block md:border-t md:border-b border-gray-300 text-xl tracking-wide py-2">
                <div className="flex justify-center items-center">
                Consumer
                </div>
              </span>
            <figcaption className="px-3 h-14 flex flex-col align-start items-center">
              <span className="block text-center px-10 sm:px-20 md:px-1 text-sm text-gray-500">
                Dashboard provides daily reporting, live balance and key data
              </span>
            </figcaption>
          </figure>

        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
